<div class="container mt-4">

  <h1 class="display-4">Budgets</h1>
  <form [formGroup]="budgetListForm">
    <div class="row">
      <div class="col-3">
        <!-- <mdate
        [dateValue]="startDate"
        [labelDateCtl]="'Start Date'"
        [DateCtlId]="'startDate'"
        (dateCtlSelectedEvent)="onStartDateChange($event)"
        ></mdate> -->
        <div class="form-group">
          <label class="text-primary" for="budgetMonth">Month</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <select id="budgetMonth" name="budgetMonth" formControlName="budgetMonth" class="form-control"
              (change)="onBudgetMonthChange($event)">
              <option value="">Choose</option>
              <option *ngFor="let month of months; let index" [value]=month.key>
                {{month.value}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-3">
        <!-- <mdate
        [dateValue]="endDate"
        [labelDateCtl]="'End Date'"
        [DateCtlId]="'endDate'"
        (dateCtlSelectedEvent)="onEndDateChange($event)"
        ></mdate> -->
        <div class="form-group">
          <label class="text-primary" for="budgetYear">Year</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <select id="budgetMonth" name="budgetYear" formControlName="budgetYear" class="form-control"
              (change)="onBudgetYearChange($event)">
              <option *ngFor="let year of years; let index" [value]=year.key>
                {{year.value}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-2 mt-2">
        <div>&nbsp;</div>
        <button class="btn btn-primary btn-sm mt-1" id="go-btn"  (click)="getBudgetsByDate()">Go</button>
      </div>

      <div class="col-4">
        <!-- <form [formGroup]="budgetListForm"> -->
          <label class="text-primary">Project Types</label>
          <select class="custom-select" name="projectTypes" id="projectTypes" formControlName="projectTypes" (change)="onProjectTypesChange($event)">
            <option value="">Choose...</option>
            <option *ngFor="let bn of budgetNames;" [value]="bn.referenceId">
              {{bn.name}}
            </option>
          </select>
        <!-- </form> -->
      </div>
    </div>
  </form>



  <div *ngIf="loading" class="spinner-border"></div>
  <div *ngIf="budgets.length === 0">No approved / opened budgets found.</div>

  <!-- header -->
  <div class="row bg-info text-white mt-3" *ngIf="budgets.length > 0">
    <div class="col-4">
      Budget
    </div>
    <div class="col-3">
      Period
    </div>
    <div class="col-1">
      Allocated
    </div>
    <div class="col-1">
      Spent
    </div>
    <div class="col-1">
      Balance
    </div>
    <div class="col-2">
      &nbsp;
    </div>
  </div>
  <!-- end of header-->

  <div *ngFor="let budget of budgets">
    <div class="row mt-1 mb-3" [attr.data-cy-budget-name]="budget.name">
      <div class="col-4">
        <div [attr.data-cy-budget-name]="budget.name">
          <h6 class="text-primary">{{budget.name}}</h6>
        </div>
        <div>{{budget.description}}</div>
        <div [attr.data-cy-carehomename]="budget.careHomeId">{{budget.careHomeName}}</div>
        <span>({{budget.spendCategoryName}})</span>
      </div>
      <div class="col-3">
        {{budget.dateFrom | date:'dd-MMM-yy'}} - {{budget.dateTo | date:'dd-MMM-yy'}}
        <div>{{budget.status}}</div>
      </div>
      <div class="col-1  text-right">
        {{budget.budgetTotal | number : '1.2-2'}}
      </div>
      <div class="col-1  text-right">
        <span>{{budget.spendTotal | number : '1.2-2'}}</span>
      </div>
      <div class="col-1 text-right">
        <span class="h6 text-success" *ngIf="(budget.budgetTotal - budget.spendTotal) > 0">
          {{ budget.budgetTotal - budget.spendTotal | number : '1.2-2'}}
        </span>
        <span class="h6 text-danger" *ngIf="(budget.budgetTotal - budget.spendTotal) < 0">
          {{ budget.budgetTotal - budget.spendTotal | number : '1.2-2'}}
        </span>
      </div>

      <div class="col-2 text-right">
        <button type="button" class="btn btn-outline-dark btn-sm" [attr.data-cy-budget-spend-detail]="budget.name"
          (click)="navToSpendList(budget.referenceId)">&#9776;</button>
        <button type="button" class="btn btn-outline-dark btn-sm ml-2" [attr.data-cy-budget-ins-spend]="budget.name"
          (click)="openModal(contentInsertSpendDialog, budget.id)">+</button>
      </div>
    </div>
  </div>






  <!-- record spent modal -->
  <ng-template #contentInsertSpendDialog let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Spend</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h6 class="text-primary">{{selectedBudget?.name}}</h6>

      <form [formGroup]="createAddSpendForm">

        <div class="form-group">
          <label class="text-primary" for="amount">Amount</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="amount" id="amount" name="amount"
              (change)="onAmountChange($event)" placeholder="" [value]="spendRequest.amount" />
          </div>
        </div>
        <div class="form-group">
          <label class="text-primary" for="comments">Comments</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <textarea class="form-control" formControlName="comments" id="comments" name="comments"
              (change)="onCommentsChange($event)" placeholder=""></textarea>
          </div>
        </div>

        <div class="form-group" *ngIf="selectedBudget.budgetType > 0">
          <label class="text-primary" for="spendStatusSelect">Spend Status</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <select class="custom-select" name="spendStatusSelect" id="spendStatusSelect" formControlName="spendStatusSelect" (change)="onSpendStatusSelectChange($event)">
              <option value="">Choose</option>
              <option *ngFor="let ss of SpendStatus | enumKeyValue" [value]="ss.value">
                {{ss.key}}
              </option>
            </select>
          </div>
        </div>

        <div>
          <label class="text-primary">PO#</label>
          <h4 class="text-success">{{poNumber}}</h4>
        </div>
      </form>

    </div>
    <div class="modal-footer">
      <div *ngIf="error" style="color: red">{{error}}</div>
      <button type="button" id="create-spend-btn" name="create-spend-btn" [disabled]="saving" class="btn btn-primary"
        (click)="createSpend()">
        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
        Submit
      </button>
    </div>
  </ng-template>
  <!-- endof comfirm modal -->




</div>