<form [formGroup]="referralForm">

  <div class="form-group">
    <label for="localAuthority">
      <h6 class="text-primary">Local Authority</h6>
    </label>
    <select class="custom-select" name="localAuthority" id="localAuthority" formControlName="localAuthority"
      (change)="onLocalAuthorityChange($event)">
      <option value="" selected>Choose...</option>
      <option *ngFor="let la of localAuthorities; let i = index" [value]="la.id">
        {{la.name}}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="laId">
      <h6 class="text-primary">LA Id</h6>
    </label>
    <input type="text" class="form-control" id="laId" name="laId" formControlName="laId"
      (change)="onLaIdChange($event)" placeholder="">
  </div>

  <div class="form-group">
    <label for="nhsNumber">
      <h6 class="text-primary">NHS Number</h6>
    </label>
    <input type="text" class="form-control" id="nhsNumber" name="nhsNumber" formControlName="nhsNumber"
      (change)="onNhsNumberChange($event)" placeholder="">
  </div>

  <div class="form-group">
    <label for="poNumber">
      <h6 class="text-primary">PO Number</h6>
    </label>
    <input type="text" class="form-control" id="poNumber" name="poNumber" formControlName="poNumber"
      (change)="onPoNumberChange($event)" placeholder="">
  </div>

  <div class="form-group">
    <label for="nymsId">
      <h6 class="text-primary">Nyms Id</h6>
    </label>
    <input type="text" class="form-control" id="nymsId" name="nymsId" formControlName="nymsId"
      (change)="onNymsIdChange($event)" placeholder="">
  </div>
</form>