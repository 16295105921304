<div class="container">
  <form [formGroup]="invoiceForm">
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label for="careHome"><strong>Care Homes</strong></label>
          <select class="custom-select" name="careHome" id="careHome" formControlName="careHome" (change)="onCareHomeChange($event)">
            <option *ngFor="let ch of careHomes;" [value]="ch.key">
              {{ch.value}}
            </option>
          </select>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="localAuthority"><strong>Local Authorities</strong></label>
          <select class="custom-select" name="localAuthority" id="localAuthority" formControlName="localAuthority" (change)="onLocalAuthorityChange($event)">
            <option *ngFor="let la of localAuthorities;" [value]="la.key">
              {{la.value}}
            </option>
          </select>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="billingCycle"><strong>Billing Cycle</strong></label>
          <select class="custom-select" name="billingCycle" id="billingCycle" formControlName="billingCycle" (change)="onBillingCycleChange($event)">
            <option *ngFor="let bc of billingCycles;" [value]="bc.id">
              ({{bc.id}}) {{bc.periodStart | date:'dd-MMM-yy'}}  -  {{bc.periodEnd | date:'dd-MMM-yy'}}
            </option>
          </select>
        </div>
      </div>

      <div class="col-1">
        <div>&nbsp;</div>
        <div class="form-group">
          <button id="report-by-billing-btn" class="btn btn-primary mt-1" [disabled]="!invoiceForm.valid" (click)="onGoClicked()">Go</button>
        </div>
      </div>

    </div>
  </form>

</div>
