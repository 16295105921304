<div class="mt-3">
  <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds">
    <ngb-panel id="panel-profile" title="Profile" class="text-white">
      <ng-template ngbPanelContent>
        <form [formGroup]="enquiryEditForm">
          <div class="form-group">
            <label for="careHome">
              <h6 class="text-primary">Care Home</h6>
            </label>
            <select
              class="custom-select"
              name="careHome"
              id="careHome"
              formControlName="careHome"
              (change)="oncareHomeChange($event)"
            >
              <option value="" selected>Choose...</option>
              <option
                *ngFor="let careHome of careHomeDetails; let i = index"
                [value]="careHome.id"
              >
                {{ careHome.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="referralAgency">
              <h6 class="text-primary">Referral Agency</h6>
            </label>
            <select
              class="custom-select"
              name="referralAgency"
              id="referralAgency"
              formControlName="referralAgency"
              (change)="onReferralAgencyChange($event)"
            >
              <option value="" selected>Choose...</option>
              <option
                *ngFor="let refAgency of referralAgency; let i = index"
                [value]="refAgency.key"
              >
                {{ refAgency.value }}
              </option>
            </select>
          </div>

          <!-- <div class="form-group">
            <input type="checkbox" name="isPrivate" id="isPrivate" formControlName="isPrivate"
              (click)="onIsPrivateChange($event)">
            <label for="isPrivate" class="ml-2">
              <h6 class="text-primary">Private</h6>
            </label>
          </div> -->
        </form>

        <resident-profile-edit
          [foreName]="enquiryResident.foreName"
          [surName]="enquiryResident.surName"
          [middleName]="enquiryResident.middleName"
          [dob]="enquiryResident.dob"
          [gender]="enquiryResident.gender"
          [maritalStatus]="enquiryResident.maritalStatus"
          (foreNameUpdated)="onForeNameUpdated($event)"
          (surNameUpdated)="onSurNameUpdated($event)"
          (middleNameUpdated)="onMiddleNameUpdated($event)"
          (dobUpdated)="onDobUpdated($event)"
          (genderUpdated)="onGenderUpdated($event)"
          (martialStatusUpdated)="onMartialStatusUpdated($event)"
        >
        </resident-profile-edit>
      </ng-template>
    </ngb-panel>

    <!-- <ngb-panel id="panel-address" title="Address">
      <ng-template ngbPanelContent>
        <address-edit [street1]="enquiryResident.address?.street1" [street2]="enquiryResident.address?.street2"
          [city]="enquiryResident.address?.city" [county]="enquiryResident.address?.county"
          [postCode]="enquiryResident.address?.postCode" (street1Updated)="onStreet1Updated($event)"
          (street2Updated)="onStreet2Updated($event)" (cityUpdated)="onCityUpdated($event)"
          (countyUpdated)="onCountyUpdated($event)" (postCodeUpdated)="onPostCodeUpdated($event)">
        </address-edit>
      </ng-template>
    </ngb-panel> -->

    <ngb-panel title="Referral Agency Details">
      <ng-template ngbPanelContent>
        <social-worker-detail-edit
          [foreName]="enquiryResident.socialWorker?.foreName"
          [surName]="enquiryResident.socialWorker?.surName"
          [phoneNumber]="enquiryResident.socialWorker?.phoneNumber"
          [emailAddress]="enquiryResident.socialWorker?.emailAddress"
          (swForeNameUpdated)="onSwForeNameUpdated($event)"
          (swSurNameUpdated)="onSwSurNameUpdated($event)"
          (swPhoneNumberUpdated)="onSwPhoneNumberUpdated($event)"
          (swEmailUpdated)="onSwEmailUpdated($event)"
        >
        </social-worker-detail-edit>
      </ng-template>
    </ngb-panel>

    <ngb-panel title="Care Type Room Details">
      <ng-template ngbPanelContent>
        <care-type-edit
          [isCareHomeSelectionChanged]="isCareHomeSelectionChanged"
          [careCategories]="careCategories"
          [careNeeds]="careNeeds"
          [stayTypes]="stayTypes"
          [careCategoryId]="enquiryResident.careCategoryId"
          [careNeed]="enquiryResident.careNeed"
          [stayType]="enquiryResident.stayType"
          (careCategoryUpdated)="onCareCategoryUpdated($event)"
          (careNeedUpdated)="oncareNeedUpdated($event)"
          (stayTypeUpdated)="onStayTypeUpdated($event)"
        >
        </care-type-edit>

        <room-location-edit
          [isCareHomeSelectionChanged]="isCareHomeSelectionChanged"
          [roomLocations]="roomLocations"
          [roomLocation]="enquiryResident.roomLocation"
          [roomNumber]="enquiryResident.roomNumber"
          (roomLocationUpdated)="onRoomLocationUpdated($event)"
          (roomNumberUpdated)="onRoomNumberUpdated($event)"
        >
        </room-location-edit>
      </ng-template>
    </ngb-panel>

    <ngb-panel title="Other">
      <ng-template ngbPanelContent>
        <enquiry-misc-edit
          [moveInDate]="enquiryResident.moveInDate"
          [labelDateCtl]="'Move in date'"
          [familyHomeVisitDate]="enquiryResident.familyHomeVisitDate"
          [comments]="enquiryResident.comments"
          (moveInDateUpdated)="onMoveInDateUpdated($event)"
          (familyHomeVisitDateUpdated)="onFamilyHomeVisitDateUpdated($event)"
          (commentsUpdated)="onCommentsUpdated($event)"
        >
        </enquiry-misc-edit>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>

<!-- status only on edit mode-->
<div class="mt-3" *ngIf="isUpdatingResident">
  <form [formGroup]="enquiryEditForm">
    <div class="form-group">
      <label for="status">
        <h6 class="text-primary">Status</h6>
      </label>
      <select
        class="custom-select"
        name="status"
        id="status"
        formControlName="status"
        (change)="onStatusChange($event)"
      >
        <option value="" selected>Choose...</option>
        <option *ngFor="let status of statuses" [value]="status.key">
          {{ status.value }}
        </option>
      </select>
    </div>
  </form>
</div>
<!-- endof status-->

<!-- save button -->
<div class="container">
  <div class="row">
    <div class="col-md">
      <div *ngIf="errors.length > 0">
        <div *ngFor="let error of errors" style="color: red">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="form-group mt-3 float-right">
        <button id="cancel" class="btn btn-secondary" (click)="onCancel()">
          Cancel
        </button>
        <button
          id="save"
          (click)="onSubmit()"
          [disabled]="saving"
          class="btn btn-primary ml-3"
        >
          <span
            *ngIf="saving"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<!-- endof save button-->
