<form [formGroup]="datectlForm">
<div class="form-group">
  <label for="datectl">
    <label class="text-primary">{{labelDateCtl}}</label>
    <span class="text-danger ml-1">*</span>
  </label>
  <div class="input-group">
    <input class="form-control" placeholder="yyyy-mm-dd"
      formControlName="datectl"  [id]="DateCtlId" [name]="DateCtlId" (dateSelect)="onDateCtlDateSelect($event)" (change)="onDateCtlDateChange($event)" ngbDatepicker #d10="ngbDatepicker">
    <div class="input-group-append">
      <!-- <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button"></button> -->
    </div>
  </div>
</div>
</form>