<div class="container mt-4">

  <h1 class="display-4">{{pageHeader}}</h1>
  <div class="mt-2">&nbsp;</div>

  <div *ngIf="loading" class="spinner-border"></div>

  <form [formGroup]="createBudgetForm">
    <div class="form-group">
      <label class="text-primary" for="spendCategory">Spend Category</label>
      <span class="text-danger ml-1">*</span>
      <div class="input-group">
        <select id="spendCategory" name="spendCategory" formControlName="spendCategory" class="form-control"
          (change)="onSpendCategoryChange($event)">
          <option *ngFor="let cate of spendCategories; let index" [value]=cate.id>
            {{cate.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="text-primary" for="careHome">Care Home</label>
      <span class="text-danger ml-1">*</span>
      <div class="input-group">
        <select id="careHome" name="careHome" formControlName="careHome" class="form-control"
          (change)="onCareHomeChange($event)">
          <option *ngFor="let ch of careHomes; let index" [value]=ch.id>
            {{ch.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="text-primary" for="name">Name</label>
      <span class="text-danger ml-1">*</span>
      <div class="input-group">
        <input type="text" class="form-control" formControlName="name" id="name" name="name"
          (change)="onNameChange($event)" placeholder="" [value]="" />
      </div>
    </div>
    <div class="form-group">
      <label class="text-primary" for="description">Description</label>
      <div class="input-group">
        <textarea class="form-control" formControlName="description" id="description" name="description"
          (change)="onDescriptionChange($event)" placeholder="" [value]=""></textarea>
      </div>
    </div>

    <div class="form-group">
      <label class="text-primary" for="budgetType">Budget Type</label>
      <span class="text-danger ml-1">*</span>
      <div class="input-group">
        <select id="budgetType" name="budgetType" formControlName="budgetType" class="form-control"
          (change)="onBudgetTypeChange($event)">
          <option *ngFor="let bt of BudgetTypes | enumKeyValueAdmin" [value]="bt.value">
            {{bt.key}}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="budget.budgetType === 0">
      <div class="form-group">
        <label class="text-primary" for="budgetMonth">Month</label>
        <span class="text-danger ml-1">*</span>
        <div class="input-group">
          <select id="budgetMonth" name="budgetMonth" formControlName="budgetMonth" class="form-control"
            (change)="onBudgetMonthChange($event)">
            <option value="">Choose</option>
            <option *ngFor="let month of months; let index" [value]=month.key>
              {{month.value}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="budget.budgetType === 1">
      <mdate [dateValue]="budget.dateFrom" [labelDateCtl]="'Date From'" [DateCtlId]="'dateFrom'"
        (dateCtlSelectedEvent)="onDateFromChange($event)"></mdate>

      <mdate [dateValue]="budget.dateTo" [labelDateCtl]="'Date To'" [DateCtlId]="'dateTo'"
        (dateCtlSelectedEvent)="onDateToChange($event)"></mdate>
    </div>

    <div class="form-group">
      <label class="text-primary" for="poCode">PO Code</label>
      <div class="input-group">
        <span class="ml-2 mt-2"><label>{{budget.poPrefix}}</label></span>
      </div>
    </div>

    <div class="form-group">
      <label class="text-primary" for="status">Status</label>
      <div class="input-group">
        <select id="status" formControlName="status" class="form-control" (change)="onStatusChange($event)">
          <option value="">Choose</option>
          <option *ngFor="let status of statuses; let index" [value]=status.key>
            {{status.value}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="text-primary" for="description">Reason</label>
      <div id="reasonReadonly" name="reasonReadonly">...</div>
      <div class="input-group">
        <textarea class="form-control" formControlName="reason" id="reason" name="reason"
          (change)="onReasonChange($event)" placeholder="" value=""></textarea>
      </div>
    </div>


    <!-- multi amounts - allocations -->
    <label class="text-primary" for="amount">Amount</label>
    <span class="text-danger ml-1">*</span>

    <div *ngFor="let alloc of budget.budgetAllocations; index as i">
      <!-- approved already - readonly -->
      <div *ngIf="alloc.approved === 'Y' && alloc.approvedById > 0">
        <div class="row">
          <div class="col-2 text-right">
            <label style="color: green">{{alloc.amount}}</label>
          </div>
          <div class="col-2">
            <label style="color: green">Approved</label>
          </div>
          <div class="col-8">
            <span>{{alloc.reason}}</span>
          </div>
        </div>
      </div>

      <!-- NOT approved - editable -->
      <!-- <div *ngIf="alloc.approved !== 'Y'"> -->
      <div *ngIf="!alloc.approvedById">
        <div class="row">
          <div class="col-2">
            <input type="text" size="20" class="form-control" formControlName="amount" id="amount" name="amount"
              (change)="onAmountChange($event, alloc.id)" placeholder="" [ngModel]="alloc.amount">
          </div>
          <div class="col-2">
            <input [attr.data-cy-approve]="'approve'" type="checkbox" name="approve" id="approve"
              (click)="onApproveChange($event, alloc.id)">
            <label class="text-primary ml-2" for="approve">Approve</label>
          </div>
        </div>
      </div>
    </div>
    <!-- endof allocations -->


    <!-- Recurrence section -->
    <div *ngIf="isAddingNewBudget === true && budget.budgetType === 0">
      <hr />
      <label class="text-danger" for="recurrenceSection">Recurrence Section</label>
      <div class="row">
        <div class="col-lg">
          <div class="form-group">
            <label class="text-primary" for="numberOfMonths">Number of months</label>
            <div class="input-group">
              <select id="numberOfMonths" name="numberOfMonths" formControlName="numberOfMonths" class="form-control"
                (change)="onNumberOfMonthsChange($event)">
                <option value="">Choose</option>
                <option *ngFor="let month of months; let index" [value]=month.key>
                  {{month.key}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>

  <!-- save button -->
  <div class="container" *ngIf="isBudgetEditable === true">
    <div class="row">
      <div class="col-md">
        <div *ngIf="errors.length > 0">
          <div *ngFor="let error of errors" style="color: red">
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="form-group mt-3 float-right">
          <span *ngIf="showAddExtraBudgetAmountButton === true">
            <button type="button" class="btn btn-warning mr-4" [attr.data-cy-budget-add-extra-amount]="budget.id"
              (click)="openModal(contentIncreaseBudgetAmount, budget.id, this.referenceId)">Increase Amount</button>
          </span>
          <button id="cancel" name="cancel" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
          <button id="save" name="save" (click)="onSubmit()" [disabled]="saving" class="btn btn-primary ml-3">
            <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">&nbsp;</div>



  <!-- dialog for Increase budget amount -->
  <ng-template #contentIncreaseBudgetAmount let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Increase Budget Amount</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h6 class="text-primary">title</h6>

      <form [formGroup]="createIncreaseAmountForm">

        <div class="form-group">
          <label class="text-primary" for="newAmount">Amount</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="newAmount" id="newAmount" name="newAmount"
              (change)="onNewAmountChange($event)" placeholder="" [value]="" />
          </div>
        </div>
        <div class="form-group">
          <input [attr.data-cy-approve]="'newApprove'" type="checkbox" name="newApprove" id="newApprove"
            (click)="onNewApproveChange($event)">
          <label class="text-primary ml-2" for="newApprove">Approve</label>
        </div>
        <div class="form-group">
          <label class="text-primary" for="newReason">Reason</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <textarea class="form-control" formControlName="newReason" id="newReason" name="newReason"
              (keydown)="onNewReasonChange($event)" placeholder="" [value]=""></textarea>
          </div>
        </div>

      </form>

    </div>
    <div class="modal-footer">
      <div *ngIf="errorsDialog.length > 0">
        <div *ngFor="let error of errorsDialog" style="color: red">
          {{ error }}
        </div>
      </div>
      <button type="button" id="create-spend-btn" name="create-spend-btn" [disabled]="saving" class="btn btn-primary"
        (click)="saveNewAmount()">
        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
    </div>
  </ng-template>
  <!-- endof comfirm modal -->







</div>