<form [formGroup]="profileForm">

  <div class="form-group">
    <label for="foreName">
      <h6 class="text-primary">Forename</h6>
    </label>
    <input type="text" class="form-control" id="foreName" name="foreName" formControlName="foreName"
      (change)="onForeNameChange($event)" placeholder="">
  </div>

  <div class="form-group">
    <label for="surName">
      <h6 class="text-primary">Surname</h6>
    </label>
    <input type="text" class="form-control" id="surName" name="surName" formControlName="surName"
      (change)="onSurNameChange($event)" placeholder="">
  </div>

  <div class="form-group">
    <label for="middleName">
      <h6 class="text-primary">Middle Name</h6>
    </label>
    <input type="text" class="form-control" id="middleName" name="middleName" formControlName="middleName"
      (change)="onMiddleNameChange($event)" placeholder="">
  </div>

  <mdate
  [dateValue]="dob"
  [labelDateCtl]="'Dob'"
  [startYear]="1500"
  [DateCtlId]="'dob'"
  (dateCtlSelectedEvent)="onDobChange($event)"
  ></mdate>

  <!-- <div class="form-group">
    <label for="dob">
      <h6 class="text-primary">DOB</h6>
    </label>
    <div class="input-group">
      <input class="form-control" placeholder="yyyy-mm-dd" formControlName="dob" id="dob" name="dob"
        [startDate]="{year: 1950, month: 01}" (dateSelect)="onDobChange($event)" (blur)="onDobBlur($event)" [placement]="placement" ngbDatepicker
        #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
  </div> -->


  <div class="form-group">
    <div>
      <label for="gender">
        <h6 class="text-primary">Gender</h6>
      </label>
    </div>

    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [attr.data-cy-gender]="'male'" class="custom-control-input" id="genderMale" formControlName="gender" name="gender"
        (change)="onGenderChange($event)" value="male">
      <label class="custom-control-label" for="genderMale">Male</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [attr.data-cy-gender]="'female'" class="custom-control-input" id="genderFemale" formControlName="gender" name="gender"
        (change)="onGenderChange($event)" value="female">
      <label class="custom-control-label" for="genderFemale">Female</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [attr.data-cy-gender]="'notdisclosed'" class="custom-control-input" id="genderNotDisclosed" formControlName="gender" name="gender"
        (change)="onGenderChange($event)" value="notdisclosed">
      <label class="custom-control-label" for="genderNotDisclosed">Not Disclosed</label>
    </div>
  </div>

  <div class="form-group">
    <div>
      <label for="maritalStatus">
        <h6 class="text-primary">Martial Status</h6>
      </label>
    </div>

    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [attr.data-cy-maritalstatus]="'married'" class="custom-control-input" id="maritalStatusMarried" formControlName="maritalStatus"
        name="maritalStatus" (change)="onMartialStatusChange($event)" value="married">
      <label class="custom-control-label" for="maritalStatusMarried">Married</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [attr.data-cy-maritalstatus]="'divorced'" class="custom-control-input" id="maritalStatusDivorced" formControlName="maritalStatus"
        name="maritalStatus" (change)="onMartialStatusChange($event)" value="divorced">
      <label class="custom-control-label" for="maritalStatusDivorced">Divorced</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [attr.data-cy-maritalstatus]="'widower'" class="custom-control-input" id="maritalStatusWidower" formControlName="maritalStatus"
        name="maritalStatus" (change)="onMartialStatusChange($event)" value="widower">
      <label class="custom-control-label" for="maritalStatusWidower">Widower</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [attr.data-cy-maritalstatus]="'single'" class="custom-control-input" id="maritalStatusSingle" formControlName="maritalStatus"
        name="maritalStatus" (change)="onMartialStatusChange($event)" value="single">
      <label class="custom-control-label" for="maritalStatusSingle">Single</label>
    </div>
  </div>
</form>