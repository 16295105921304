<div class="container mt-1">

  <date-range-selection
    (startDateSelectEvent)="onStartDateSelectEvent($event)"
    (endDateSelectEvent)="onEndDateSelectEvent($event)"
    (reportSelectorChangedEvent)="reportSelectorChanged($event)"
    (getReportEvent)="getReport()"
    (downloadReportEvent)="downloadReport()">
  </date-range-selection>

  <!-- below both components shows in one page [Summary] page contains full rpt and summary ttls -->
  <summary-info *ngIf="reportSelector === '1'"
    [startDate]="startDate"
    [endDate]="endDate">
  </summary-info>

  <report-list *ngIf="reportSelector === '1'"
    [startDate]="startDate"
    [endDate]="endDate">
  </report-list>

  <report-validated-unvalidated *ngIf="reportSelector === '3'"
    [startDate]="startDate"
    [endDate]="endDate">
  </report-validated-unvalidated>

  <report-occupancy-by-day *ngIf="reportSelector === '4'"
    [startDate]="startDate"
    [endDate]="endDate">
  </report-occupancy-by-day>

  <report-spend-adjustments *ngIf="reportSelector === '5'"
  [startDate]="startDate"
  [endDate]="endDate">
</report-spend-adjustments>

</div>
