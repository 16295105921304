<div class="container mt-5">

  <div class="row">
    <div class="col-md">
      <div class="card" style="width: 18rem; height: 14rem;">
        <div class="card-header bg-info text-white" >
          <h5 class="card-title">Spends</h5>
        </div>
        <div class="card-body bg-light">
          <p class="card-text">Manage spends for care homes</p>
          <div>
            <button id="user-spends-btn" class="btn btn-primary" style="width: 12rem;" (click)="navToUserBudgets()">Budgets and Spend</button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
