<div class="container mt-4">

  <h1 class="display-4">Edit Schedules</h1>
  <div *ngIf="loading" class="spinner-border"></div>

  <div class="container">
    <div class="row">
      <div class="col-md">
        <h5 class="text-primary text-lg font-weight-bold">
          {{resident?.foreName}} {{resident?.surName}}
        </h5>
      </div>
      <div class="col-md text-right">
        <button id="add-schedule" class="btn btn-primary btn-sm" (click)="openModal(contentAdd, 0)">Add</button>
      </div>
    </div>
  </div>
  <hr />

  <div class="container" *ngIf="residentSchedules.schedules.length > 0">
    <div class="row bg-info text-white mb-3">
      <div class="col-md">
        <div><strong>From</strong></div>
      </div>
      <div class="col-md">
        <div><strong>Description</strong></div>
      </div>
      <div class="col-lg">
        <div><strong>ScheduleBeginDate</strong></div>
      </div>
      <div class="col-lg">
        <div><strong>ScheduleEndDate</strong></div>
      </div>
      <div class="col-sm text-right">
        <div><strong>WeeklyFee</strong></div>
      </div>
      <div class="col-sm text-right">
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row mt-1" *ngFor="let schedule of residentSchedules.schedules">
      <div class="col-md">
        {{schedule.paymentFrom}}
        <span *ngIf="schedule.paymentFromName" style="font-size: .8rem;">{{schedule.paymentFromName | slice:0:8}}...</span>
      </div>
      <div class="col-lg">
        {{schedule.description}}
      </div>
      <div class="col-lg">
        {{schedule.scheduleBeginDate | date}}
      </div>
      <div class="col-md">
        {{schedule.scheduleEndDate | date}}
      </div>
      <div class="col-md text-right">
        {{schedule.weeklyFee | number : '1.2-2'}}
      </div>
      <div class="col-md text-right">
        <!-- <button type="button" class="btn btn-outline-dark btn-sm"
          [attr.data-cy-schedule-expire]="schedule.description"
          (click)="openModal(content, schedule.id)">Expire</button> -->
        <button type="button" class="btn btn-outline-dark btn-sm mr-1"
          [attr.data-cy-schedule-delete]="schedule.description"
          (click)="openModal(contentConfirm, schedule.id)">Delete</button>
          <button type="button" class="btn btn-outline-dark btn-sm mr-1"
          [attr.data-cy-schedule-edit]="schedule.description"
          (click)="openModal(contentAdd, schedule.id)">Edit</button>
      </div>
    </div>
  </div>


  <div class="font-sm mt-5">
    <hr />
    Note: When a resident move out or passed away, update exit date in [residents] tab.
    <hr />
  </div>

  <!-- set schedule end date modal -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Schedule End</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
          <label for="dateOfExit">Exit Date</label>
          <div class="input-group">
            <input id="dateOfExit" class="form-control" placeholder="yyyy-mm-dd" name="dp" (dateSelect)="onScheduleEndDateChange_1($event)" (blur)="onScheduleEndDateBlur_1($event)" ngbDatepicker #dp="ngbDatepicker">
             <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar"  (click)="dp.toggle()" type="button"></button>
            </div>
          </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" id="save-schedule-exit" [disabled]="saving" class="btn btn-primary" (click)="updateScheduleEndDate()">
        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
    </div>
  </ng-template>
  <!-- endof schedule end date -->


  <!-- modal for create schedule -->
  <ng-template #contentAdd let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Create Schedule</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <form [formGroup]="createScheduleForm">
        <div class="form-group">
          <label class="text-primary" for="localAuthority">LA / Fund Providers</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <select id="localAuthority" formControlName="localAuthority" class="form-control" (change)="onLocalAuthorityChange($event)">
              <option  *ngFor="let la of _localAuthorities; let index" [value]=la.id>
                {{la.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="text-primary" for="paymentFrom">Payment Providers</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <select id="paymentFrom" formControlName="paymentFrom" class="form-control" (change)="onPaymentFromChange($event)">
              <option *ngFor="let pp of paymentProviders; let i = index" [value]="pp.id">
                {{pp.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="text-primary" for="paymentType">Payment Type</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <select id="paymentType" formControlName="paymentType" class="form-control" (change)="onPaymentTypeChange($event)">
              <option *ngFor="let pt of paymentTypes; let i = index" [value]="pt.id">
                {{pt.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="text-primary" for="description">Description</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="description"  id="description" name="description" (change)="onDescriptionChange($event)" placeholder="" [value]="newSchedule.description">
          </div>
        </div>

        <mdate
        [dateValue]="scheduleBeginDate"
        [labelDateCtl]="'Schedule Begin Date'"
        [DateCtlId]="'scheduleBeginDate'"
        (dateCtlSelectedEvent)="onScheduleBeginDateChange($event)"
        ></mdate>

        <mdate
        [dateValue]="scheduleEndDate"
        [labelDateCtl]="'Schedule End Date'"
        [DateCtlId]="'scheduleEndDate'"
        (dateCtlSelectedEvent)="onScheduleEndDateChange($event)"
        ></mdate>

        <div class="form-group">
          <label class="text-primary" for="weeklyfee">Weekly Fee</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <input type="number" class="form-control" formControlName="weeklyFee" id="weeklyFee" name="weeklyFee" (change)="onWeeklyFeeChange($event)" placeholder="">
          </div>
        </div>
      </form>

    </div>
    <div class="modal-footer">
      <div *ngIf="error">{{error}}</div>
      <button type="button" id="create-schedule" [disabled]="saving" class="btn btn-primary" (click)="createNewSchdule()">
        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
    </div>
  </ng-template>
<!-- endof modal for create schedule -->


  <!-- confirm modal -->
  <ng-template #contentConfirm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirm</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
          <span>Are you sure you want to delete? </span>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" id="schedule-delete-btn" [disabled]="saving" class="btn btn-primary" (click)="disableSchedule()">
        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
    </div>
  </ng-template>
  <!-- endof comfirm modal -->

</div>