
<div class="container mt=3">
  <div *ngIf="loading" class="spinner-border"></div>

  <!-- summary -->
  <div class="mt-3" *ngIf="avgOccupancyReport && avgOccupancyReport.length > 0">
    <span class="text-primary size=3">Average Occupancy for the period of:&nbsp;</span>
    <span>{{startDate}} - {{endDate}}</span>

    <div class="row" *ngFor="let avg of avgOccupancyReport">
      <div class="col-lg">
        <span>{{avg.name}}</span>
      </div>
      <div class="col-lg">
        <span>{{avg.averageOccupancy | number:'1.2-2'}}</span>
      </div>
    </div>
  </div>

  <!-- Occupancy by division -->
  <div style="display: block;" class="mt-3">
    <div *ngIf="barChartLabelsDivisions.length > 0 && barChartDataDivisions.length > 0">
      <span class="text-primary size=3">Occupancy by divisions</span>

      <canvas baseChart
      [datasets]="barChartDataDivisions"
      [labels]="barChartLabelsDivisions"
      [options]="barChartOptions"
      [colors]="barChartColors"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [chartType]="barChartType">
    </canvas>
    </div>
  </div>


  <!-- Occupancy by fund providers -->
  <div style="display: block;" class="mt-3">
    <div *ngIf="barChartLabelsFundProviders.length > 0 && barChartDataFundProviders.length > 0">
      <span class="text-primary size=3">Occupancy by providers</span>

      <canvas baseChart
      [datasets]="barChartDataFundProviders"
      [labels]="barChartLabelsFundProviders"
      [options]="barChartOptions"
      [colors]="barChartColors"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [chartType]="barChartType">
    </canvas>
    </div>
  </div>

  <div class="mt-3" (click)="toggleData()" *ngIf="avgOccupancyReport && avgOccupancyReport.length > 0">Show Data...</div>
  <div *ngIf="showData">
    <div class="row" style="font-size: small;"  *ngFor="let pd of printData">
      <div class="col-3">
        <span class="text-primary">{{pd.name}}</span>
      </div>
      <div class="col-2">
        <span>{{pd.thisDate | date:'dd-MMM'}}</span>
      </div>
      <div class="col-2">
        <span>{{pd.occupancyCount}}</span>
      </div>
    </div>
  </div>

  <div class="mb-3">
    &nbsp;
  </div>

</div>