<!-- Home page -->
<div class="container mt-5">

  <div *ngIf="isLoading">loading...</div>

  <div class="jumbotron">
    <h1 class="display-5 text-dark">NYMS - Resident Care System</h1>
    <p class="lead"></p>
    <hr class="my-4">
    <p>Manage resident and enquires for our care homes</p>
    <p class="lead">
    </p>
  </div>

</div>
