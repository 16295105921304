<div class="container mt-3">

  <div class="container">
    <div class="row">
      <div class="col-md">
        <h1 class="display-4">Enquires</h1>
      </div>
      <div class="col-md">
        <div class="float-right">
          <button id="add-enquiry-btn" class="btn btn-primary" (click)="navToAddEnquiry()">Add</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row bg-info text-white mb-3">
      <div class="col-sm">
        <div class="h6">Name</div>
      </div>
      <!-- <div class="col-sm">
        <div class="h6">DOB</div>
      </div>
      <div class="col-sm">
        <div class="h6">Updated Date</div>
      </div>
      <div class="col-sm">
        <div class="h6">Local Auth.</div>
      </div> -->
      <div class="col-sm">
        <div class="h6">Care Category</div>
      </div>
      <div class="col-sm">
        <div class="h6">Care Need</div>
      </div>
      <div class="col-sm">
      </div>
    </div>

    <div class="row">
      <div *ngIf="loading" class="spinner-border"></div>
      <div class="col-md">
        <enquires-list *ngFor="let enquiry of enquiryResidents"
          [enquiryResident]="enquiry">
        </enquires-list>
      </div>
    </div>
  </div>

</div>

