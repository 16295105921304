<form [formGroup]="socialWorkerForm">
  <div class="form-group">
    <label for="swForeName">
      <h6 class="text-primary">Fore Name</h6>
    </label>
    <input
      type="text"
      class="form-control"
      id="swForeName"
      name="swForeName"
      formControlName="swForeName"
      (change)="onSwForeNameChange($event)"
      placeholder=""
    />
  </div>

  <div class="form-group">
    <label for="swSurName">
      <h6 class="text-primary">Sur Name</h6>
    </label>
    <input
      type="text"
      class="form-control"
      id="swSurName"
      name="swSurName"
      formControlName="swSurName"
      (change)="onSwSurNameChange($event)"
      placeholder=""
    />
  </div>

  <div class="form-group">
    <label for="phoneNumber">
      <h6 class="text-primary">Phone Number</h6>
    </label>
    <input
      type="text"
      class="form-control"
      id="phoneNumber"
      name="phoneNumber"
      formControlName="phoneNumber"
      (change)="onSwPhoneNumberChange($event)"
      placeholder=""
    />
  </div>

  <div class="form-group">
    <label for="emailAddress">
      <h6 class="text-primary">emailAddress</h6>
    </label>
    <input
      type="text"
      class="form-control"
      id="emailAddress"
      name="emailAddress"
      formControlName="emailAddress"
      (change)="onSwEmailChange($event)"
      placeholder=""
    />
  </div>
</form>
