<form [formGroup]="careTypeForm">

  <div class="form-group">
    <label for="careCategoryId">
      <h6 class="text-primary">Care Category</h6>
    </label>
    <select class="custom-select" name="careCategoryId" id="careCategoryId" formControlName="careCategoryId"
      (change)="onCareCategoryChange($event)">
      <option value="" selected>Choose...</option>
      <option *ngFor="let item of careCategories;" [value]="item.id">
        {{item.name}}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="careNeed">
      <h6 class="text-primary">Care Needs</h6>
    </label>
    <select class="custom-select" name="careNeed" id="careNeed" formControlName="careNeed"
      (change)="oncareNeedChange($event)">
      <option value="" selected>Choose...</option>
      <option *ngFor="let item of careNeeds;" [value]="item.key">
        {{item.value}}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="stayType">
      <h6 class="text-primary">Stay Type</h6>
    </label>
    <select class="custom-select" name="stayType" id="stayType" formControlName="stayType"
      (change)="onStayTypeChange($event)">
      <option value="" selected>Choose...</option>
      <option *ngFor="let item of stayTypes;" [value]="item.key">
        {{item.value}}
      </option>
    </select>
  </div>

</form>