<div class="container mt-3">

  <h1 class="display-4">Residents</h1>

  <div class="row bg-info text-white mb-3">
    <!-- <div class="col-sm">
      <div><strong>RefId</strong></div>
    </div> -->
    <div class="col-sm">
      <div><strong>Name</strong></div>
    </div>
    <div class="col-sm">
      <div><strong>Dob</strong></div>
    </div>
    <div class="col-sm">
      <div><strong>Admission</strong></div>
    </div>
    <div class="col-sm">
      <div><strong>Discharged From Home</strong></div>
    </div>
    <div class="col-sm">
      <div><strong>Exit (Schedule)</strong></div>
    </div>

    <div class="col-sm">
      <div><strong>&nbsp;</strong></div>
    </div>
    <div class="col-sm">
      <div><strong>&nbsp;</strong></div>
    </div>
  </div>

  <div *ngIf="loading" class="spinner-border"></div>

  <div class="row" *ngFor="let res of residents">
    <div class="col-sm">
      <div>{{res.foreName}} {{res.surName}}</div>
    </div>
    <div class="col-sm">
      <div>{{res.dob | date:'dd-MMM-yy'}}</div>
    </div>
    <div class="col-sm">
      <div>{{res.admissionDate | date:'dd-MMM-yy'}}</div>
    </div>
    <div class="col-sm">
      <div>{{res.dischargedFromHomeDate | date:'dd-MMM-yy'}}</div>
    </div>
    <div class="col-sm">
      <div>{{res.exitDate | date:'dd-MMM-yy'}}</div>
    </div>

    <div class="col-sm">
      <span>
        <a [attr.data-cy-res-edit]="res.foreName+'_'+res.surName" [routerLink]="['/residents-edit', res.referenceId]">Edit</a>
      </span>
      <span class="ml-3">
        <a [attr.data-cy-res-view]="res.foreName+'_'+res.surName" [routerLink]="['/residents-view', res.referenceId]">View</a>
      </span>
    </div>
    <div class="col-sm">
      <!--  -->
      <div *ngIf="res.dischargedFromHomeDate === '9999-12-31T00:00:00'">
        <button type="button" class="btn btn-outline-danger btn-sm" style="width: 7.5em;"  [attr.data-cy-discharge]="res.foreName+'_'+res.surName" (click)="openModal(contentDischarge, res.referenceId)">Discharge</button>
      </div>
      <div *ngIf="res.exitDate === '9999-12-31T00:00:00'">
        <button type="button" class="btn btn-outline-dark btn-sm" style="width: 7.5em;" [attr.data-cy-exitdate]="res.foreName+'_'+res.surName" (click)="openModal(contentExitSchedule, res.referenceId)">Exit Schedule</button>
      </div>
      <div *ngIf="res.exitDate !== '9999-12-31T00:00:00'">
        <button type="button" class="btn btn-outline-dark btn-sm" style="width: 7.5em;" [attr.data-cy-activate]="res.foreName+'_'+res.surName" (click)="openModal(contentActivate, res.referenceId)">Activate</button>
      </div>
    </div>
    <div class="h-divider"></div>
  </div>

  <div class="mt-4"></div>


  <ng-template #contentDischarge let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Discharge - {{selectedName}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <mdate
        [dateValue]="dischargeDate"
        [labelDateCtl]="'Discharge Date'"
        [DateCtlId]="'dischargeDate'"
        (dateCtlSelectedEvent)="onDischargeDateChange($event)"
        ></mdate>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" id="save" name="save" (click)="dischargeResident()">Save</button>
    </div>
  </ng-template>

  <ng-template #contentExitSchedule let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Exit Schedule - {{selectedName}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <mdate
        [dateValue]="exitDate"
        [labelDateCtl]="'Exit Date'"
        [DateCtlId]="'exitDate'"
        (dateCtlSelectedEvent)="onExitDateChange($event)"
        ></mdate>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" id="save" name="save" (click)="exitResidentSchedule()">Save</button>
    </div>
  </ng-template>

  <ng-template #contentActivate let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Activate - {{selectedName}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to activate?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" id="saveActivate" name="saveActivate" (click)="activateResident()">Activate</button>
    </div>
  </ng-template>

</div>
