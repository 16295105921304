<div class="container">

  <div class="container">
    <div class="row bg-info text-white mb-3">
      <div class="col-2">
        Name
      </div>
      <div class="col-3">
        Description
      </div>
      <div class="col-1">
        Stay
      </div>
      <div class="col-2 text-right">
        Amount Due
      </div>
      <div class="col-3">
        Validation
      </div>
      <div class="col-1">

      </div>
    </div>
  </div>

  <!-- <p>==--{{invoiceData | json}}</p> -->

  <div class="container">
    <form [formGroup]="reportListWithValidationForm">
      <div *ngIf="invoiceData.invoiceResidents">

      <div  *ngFor="let invoice of invoiceData.invoiceResidents" class="mt-3">
        <div class="row" id="grid-data" *ngFor="let schedule of invoice.schedulePayments">
          <div class="col-2">
            <span >{{invoice.name}}</span>
          </div>
          <div class="col-3">
            <span>{{schedule.description}} <span style="font-size: small;">(@ {{schedule.weeklyFee}})</span></span>
          </div>
          <div class="col-1">
            <span>{{schedule.numberOfDays}}</span>
          </div>
          <div class="col-2 text-right">
            <span [attr.data-cy-amount]="invoice.name+'_'+schedule.amountDue">{{schedule.amountDue | number : '1.2-2'}}</span>
          </div>
          <div class="col-3 align-center"> <!--validation-->
            <div class="form-group" >
              <input *ngIf="schedule.invoiceValidatedModel.id === 0" [attr.data-cy-isvalid]="invoice.name+'_'+schedule.amountDue" type="checkbox" name="isValid" id="isValid" formControlName="isValid"
                (click)="onIsValidChange($event, schedule.id)" [ngModel]="myvalue">
            </div>
            <div class="form-group small" *ngIf="schedule.invoiceValidatedModel.id > 0">
              <div>By: {{schedule.invoiceValidatedModel?.updatedBy}} On: {{schedule.invoiceValidatedModel.updatedDate | date}}</div>
              <div>For: {{schedule.invoiceValidatedModel?.validatedAmount | number : '1.2-2'}}</div>
              <div *ngIf="schedule.amountDue !== schedule.invoiceValidatedModel.validatedAmount">
                <span class="text-danger" style="font-size: smaller;">Var: {{schedule.amountDue - schedule.invoiceValidatedModel.validatedAmount | number : '1.2-2'}}</span>
              </div>
            </div>
          </div>
          <div class="col-1">
            <button type="button" class="btn btn-outline-dark btn-sm"
            (click)="openModal(content, schedule.id, schedule)">+</button>

              <!-- popover for comments-->
              <ng-template #popContent2 >
                <div class="popover-body">
                  <div *ngFor="let cmt of schedule.comments">
                    {{cmt}}
                  </div>
                </div>
              </ng-template>
              <span class="ml-2" *ngIf="schedule.comments && schedule.comments.length > 0" style="cursor: pointer;"
                [ngbPopover]="popContent2" placement="left">...</span>
              <!-- endof popover for comments-->
          </div>

        </div>
        <div class="h-divider"></div>
      </div>

      <div class="row mt-3">
        <div class="col-md text-right">
          <button type="button" id="save" [disabled]="saving" [disabled]="!hasDataToSave" class="btn btn-primary" (click)="save()">
            <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
            Save
          </button>
        </div>
      </div>
    </div>
    </form>
  </div>



  <!-- add discrepency amt in report with validation pg -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Comments</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <!-- <label for="comments">Comments</label> -->
        <div class="input-group">
          <input type="text" class="form-control" id="comments" name="comments"
            (change)="onCommentsChange($event)" placeholder="">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="saving" class="btn btn-primary" (click)="saveComments()">
        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
    </div>
  </ng-template>
  <!-- endof schedule end date -->


</div>







    <!-- modal comments -->
    <!-- <ng-template #content_comments let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Comments</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngFor="let cmt of displayComments">
          {{cmt}}
        </div>
      </div>
    </ng-template> -->
    <!-- endof schedule end date -->
    <!-- <span class="ml-2" *ngIf="schedule.comments && schedule.comments.length > 0" style="cursor: pointer;"
      (click)="openModalComments(content_comments, schedule.comments)">...</span> -->



