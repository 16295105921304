<form [formGroup]="nokForm">

  <div class="form-group">
    <label for="nokForeName">
      <h6 class="text-primary">Forename</h6>
    </label>
    <input type="text" class="form-control" id="nokForeName" name="nokForeName" formControlName="nokForeName"
      (change)="onNokForeNameChange($event)" placeholder="">
  </div>

  <div class="form-group">
    <label for="nokSurName">
      <h6 class="text-primary">Surname</h6>
    </label>
    <input type="text" class="form-control" id="nokSurName" name="nokSurName" formControlName="nokSurName"
      (change)="onNokSurNameChange($event)" placeholder="">
  </div>

  <div class="form-group">
    <label for="relationship">
      <h6 class="text-primary">Relationship</h6>
    </label>
    <input type="text" class="form-control" id="relationship" name="relationship" formControlName="relationship"
      (change)="onRelationshipChange($event)" placeholder="">
  </div>

</form>
