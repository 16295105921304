<form [formGroup]="enquiryMiscForm">

  <mdate
  [dateValue]="moveInDate"
  [labelDateCtl]="labelDateCtl"
  [startYear]="1500"
  [DateCtlId]="'moveInDate'"
  (dateCtlSelectedEvent)="onMoveInDateChange($event)"
  ></mdate>

  <mdate
  [dateValue]="familyHomeVisitDate"
  [labelDateCtl]="'Home Visit Date'"
  [DateCtlId]="'familyHomeVisitDate'"
  (dateCtlSelectedEvent)="onFamilyHomeVisitDateChange($event)"
  ></mdate>

  <div class="form-group">
    <label for="comments">
      <h6 class="text-primary">Comments</h6>
    </label>
    <textarea rows="4" class="form-control" id="comments" name="comments" formControlName="comments" (change)="onCommentsChange($event)" placeholder=""></textarea>
  </div>

</form>
