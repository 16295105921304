<div class="container">
  <form [formGroup]="invoiceForm">
  <div class="row">
    <div class="col-sm">
      <mdate
      [dateValue]="startDate"
      [labelDateCtl]="'Start Date'"
      [DateCtlId]="'startDate'"
      (dateCtlSelectedEvent)="onStartDateChange($event)"
      ></mdate>
    </div>

    <div class="col-sm">
      <mdate
      [dateValue]="endDate"
      [labelDateCtl]="'End Date'"
      [DateCtlId]="'endDate'"
      (dateCtlSelectedEvent)="onEndDateChange($event)"
      ></mdate>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <label for="reportSelector">Report Selector</label>
        <select class="custom-select" name="reportSelector" id="reportSelector" formControlName="reportSelector" (change)="onReportSelectorChange($event)">
          <option value="1" [selected]="1">Summary</option>
          <option value="3">Validations</option>
          <option value="4">Occupancy</option>
          <option value="5">Spends</option>
        </select>
      </div>
    </div>

    <div class="col-sm">
      <div>&nbsp;</div>
      <button class="btn btn-primary mt-1" id="go-btn"  (click)="getReportByDate()">Go</button>
      <button class="btn btn-primary mt-1 ml-4" (click)="downloadReportByDate()">Download
      </button>
    </div>
  </div>

  <div class="row" *ngIf="error">
    <div class="col-6 text-danger">{{error}}</div>
  </div>

</form>
</div>
