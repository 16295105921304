<!-- Enquires List page. Lists all active enquires -->
<div class="container">

  <div class="row">
    <div class="col-md">
      {{enquiryResident.foreName}} {{enquiryResident.surName}}
    </div>
    <!-- <div class="col-sm">
      <div *ngIf="enquiryResident.dob">{{enquiryResident.dob | date}}</div>
    </div>
    <div class="col-sm">
      <div *ngIf="enquiryResident?.updatedDate">{{enquiryResident.updatedDate | date}}</div>
    </div>
    <div class="col-sm">
      <div>{{enquiryResident.localAuthorityName}}</div>
    </div> -->
    <div class="col-md">
      <div>{{enquiryResident.careCategoryName}}</div>
    </div>
    <div class="col-md">
      <div>{{enquiryResident.careNeed | titlecase }}</div>
    </div>
    <div class="col-sm">
      <span>
        <a [attr.data-cy-enq-edit]="enquiryResident.foreName+'_'+enquiryResident.surName" [routerLink]="['/enquires-edit', enquiryResident.referenceId]">Edit</a>
      </span>
      <span class="ml-3">
        <a [routerLink]="['/enquires-action', enquiryResident.referenceId]">Actions</a>
      </span>
      <span class="ml-3">
        <a [attr.data-cy-enq-admit]="enquiryResident.foreName+'_'+enquiryResident.surName" [routerLink]="['/residents-admit', enquiryResident.referenceId]">Admit</a>
      </span>
    </div>
  </div>
  <div class="h-divider"></div>
</div>
