<div class="container">
  <hr *ngIf="invoices.length > 0" />
  <div *ngFor="let invoice of invoices">
    <h5 class="text-primary">{{invoice.name}}</h5>
    <span style="font-size: small;">Weekly Fee (@ {{invoice.residentWeeklyFee}})</span>

    <div class="row" *ngFor="let schedule of invoice.schedulePayments">
      <div class="col-md" >
        {{schedule.paymentFromName}}
      </div>
      <div class="col-md">
        {{schedule.description}} <span style="font-size: small;">(@ {{schedule.weeklyFee}}) ({{schedule.numberOfDays}})</span>
      </div>
      <div class="col-md text-right">
        <span [attr.data-cy-amount]="invoice.name+'_'+schedule.amountDue">
          {{schedule.amountDue | number : '1.2-2'}}
        </span>
      </div>
    </div>

    <div class="row" *ngIf="schedule?.localAuthorityId > 0">
      <div class="col-md">
        &nbsp;
      </div>
      <div class="col-md">
        <strong>Total La Fee:</strong>
      </div>
      <div class="col-md text-right">
        <span [attr.data-cy-amount]="invoice.name+'_'+schedule.amountDue">
          <strong>{{invoice.totalLaFee | number : '1.2-2'}}</strong>
        </span>
      </div>
    </div>

    <div class="row border mb-4">
      <div class="col-md">
        &nbsp;
      </div>
      <div class="col-md">
        <strong>Grand Total:</strong>
      </div>
      <div class="col-md text-right">
        <strong>{{invoice.grandTotal | number : '1.2-2'}}</strong>
      </div>
    </div>

  </div>
</div>
