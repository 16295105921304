<!-- nav -->
  <header class="navbar navbar-expand navbar-dark bg-dark">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']">Home</a>
      </li>

      <!-- Authorized only -->
      <li class="nav-item" *ngIf="isLoggedIn">
        <a data-cy="nav-link-residents" class="nav-link" [routerLink]="['residents-dashboard']" id="residents-link">Residents</a>
      </li>

      <li class="nav-item" *ngIf="isLoggedIn">
        <a data-cy="nav-link-user-actions" class="nav-link" [routerLink]="['user-actions-dashboard']" id="user-actions-link">Actions</a>
      </li>

      <!-- Admin only -->
      <li class="nav-item" *ngIf="isAdmin && isLoggedIn">
        <a data-cy="nav-link-admin" class="nav-link" [routerLink]="['admin-dashboard']" id="admin-link">Admin</a>
      </li>

      <!-- Anonymous only -->
      <!-- <li class="nav-item" *ngIf="!isAuthorized">
        <a class="nav-link" [routerLink]="['login']">Login</a>
      </li> -->

      <!-- Authorized only -->
      <li class="nav-item" *ngIf="isLoggedIn">
        <a data-cy="nav-link-logout" class="nav-link" (click)="logout()" id="logout-link">Logout</a>
      </li>

    </ul>

    <span class="navbar-text">
      {{user?.foreName}} {{user?.surName}}
    </span>
</header>

<!-- main app container -->
<div class="container">
  <router-outlet></router-outlet>
</div>