<div class="container mt-1">

  <billing-date-selection
    [careHomes]="careHomes"
    [localAuthorities]="localAuthorities"
    [billingCycles]="billingCycles"
    (careHomeChangedEvent)="onCareHomeChange($event)"
    (localAuthorityChangedEvent)="onLocalAuthorityChange($event)"
    (billingCycleChangedEvent)="onBillingCycleChangedEvent($event)"
    (getReportEvent)="onGetReportEvent()">
  </billing-date-selection>

  <div class="container">
    <div class="row">
      <div class="col-md">
        <strong>Billing Cycle Id: {{_invoiceData?.billingCycleId}}</strong>
      </div>
      <div class="col-md">
        <strong>Billing Days: {{_invoiceData?.numberOfDays}}</strong>
      </div>
    </div>
  </div>
  <hr />

  <div id="report-grid">
    <report-list-with-validation
      [invoiceData]="_invoiceData"
      (invoiceValidatedSaveEvent)="onInvoiceValidatedSave()">
    </report-list-with-validation>

    <div *ngIf="loading" class="spinner-border"></div>
    <p class="text-danger" *ngIf="error">{{error}}</p>
  </div>

</div>
